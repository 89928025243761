.grid {
  display: grid;
  box-sizing: border-box;

  @include tablet-landscape {
    grid-template-columns: var(--grid-template-columns-2-3);
    grid-gap: var(--grid-gap);
  }

  &.grid-sidebar {

    @include tablet-landscape {
      grid-template-columns: 1fr 400px;
    }
  }

  .card-list {
    margin: 0;
  }

  .grid-sticky .card {

    @include tablet-landscape {
      position: sticky;
      top: 0;
    }
  }
}
