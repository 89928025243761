.alert {
  position: relative;
  padding: calc(var(--base) / 2) var(--base);
  color: var(--pr-color);
  background-color: var(--pr-bg-body);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;

  &.dispose { // Dismissable alerts
    display: none;
  }

  .alert-heading {
    color: inherit;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
  }

  .alert-link {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
      opacity: 0.75;
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: inherit;
    padding: 0.4rem 0.6rem;
    line-height: 1;
    font-size: 1.6rem;
    background-color: transparent;
    border-color: transparent;

    &:focus {
      outline: 2px solid var(--pr-border-alert-outline);
      outline-offset: 0.2rem;
    }
  }
}

/* Sticky alerts */

.sticky-alerts {

  .alert {
    display: none;
    width: 27rem;
    margin-top: 1rem;
    right: -50rem; /* Hidden by default */
    box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.045);

    .show {
      right: 0;
      transition: right .4s cubic-bezier(.25, .8, .25, 1);
    }

    .fade {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s .4s, opacity .4s linear;
    }

    .alert-block {
      display: block;

      &.dispose { // Dismissable alerts
        display: none;
      }
    }
  }
}

/* Alerts colors (primary, success, secondary, danger) */
/* Filled alerts use the same design with both themes */

.alert-primary {
  color: rgba(0, 0, 0, 0.85);
  background-color: #cfe7fd;
  border-color: #1890ff;

  &.filled {
    color: #ffffff !important;
    background-color: var(--c-primary) !important;
    border-color: var(--c-primary) !important;
  }
}

.alert-success {
  color: rgba(0, 0, 0, 0.85);
  background-color: #d5f9e5;
  border-color: #0be881;

  &.filled {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: var(--c-success) !important;
    border-color: var(--c-success) !important;
  }
}

.alert-secondary {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff6cc;
  border-color: #ffcf00;

  &.filled {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: var(--c-secondary) !important;
    border-color: var(--c-secondary) !important;
  }
}

.alert-danger {
  color: rgba(0, 0, 0, 0.85);
  background-color: #f8d0d9;
  border-color: #ff4d4f;

  &.filled {
    color: #ffffff !important;
    background-color: var(--c-danger) !important;
    border-color: var(--c-danger) !important;
  }
}

/* Dark mode */

.dark-mode {

  .alert-primary {
    color: #1890ff;
    background-color: #172636;
    border-color: #0d518f;
  }

  .alert-success {
    color: #0be881;
    background-color: #17312a;
    border-color: #0d8951;
  }

  .alert-secondary {
    color: #ffcf00;
    background-color: #302c1d;
    border-color: #8e7710;
  }

  .alert-danger {
    color: #ff4d4f;
    background-color: #301923;
    border-color: #8c0e2e;
  }
}
