html {
  font-size: 62.5%;
}

h1 {
  font-size: 3.6rem;
  line-height: 1.35;
  margin: var(--spacing-unit) 0;
  font-weight: 400;
}

h2 {
  font-size: 3rem;
  line-height: 1.4;
  margin: var(--spacing-unit) 0;
  font-weight: 400;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.45;
  margin: var(--spacing-unit) 0;
  font-weight: 400;
}

h4 {
  font-size: 1.8rem;
  margin: var(--spacing-unit) 0;
  font-weight: 400;
}

h5 {
  font-size: 1.5rem;
  margin: var(--spacing-unit) 0;
  font-weight: 400;
}

blockquote {
  border-left: 3px solid var(--pr-border-color);
  font-style: italic;
  padding-left: var(--spacing-unit);
}

.with-separator {
  margin-bottom: calc(var(--spacing-unit) / 2);
  padding-bottom: calc(var(--spacing-unit) / 2);
  border-bottom: 1px solid var(--pr-border-color);
}

.c-link {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: var(--c-primary);
  cursor: pointer;

  &:hover {
    color: var(--c-primary-accent);
  }
}
