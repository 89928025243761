#root {
  counter-reset: accordion;
}

.accordion {
  display: flex;
  flex-direction: column;

  &.has-error {

    .accordion-header {
      box-shadow: inset 3px 0 0 0 #ff4d4f;
    }
  }

  &:first-of-type {

    .accordion-header {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }

  &:last-of-type {

    .accordion-header {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);

      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        + .accordion-body {
          border-bottom: 1px solid var(--pr-accordion-border);
          border-bottom-left-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }
      }
    }
  }

  &:not(:first-child) {
    margin-top: calc((-1) * 1px);
  }

  &:not(:last-child) {

    .accordion-header {
      border-bottom: none;
    }
  }

  &.accordion-counter {

    .accordion-header {
      position: relative;
      display: flex;
      padding-left: calc(var(--base) * 4);

      &:before {
        position: absolute;
        left: calc(var(--base) * 1.3);
        counter-increment: accordion;
        content: counters(accordion, ".") " ";
        width: calc(var(--base) * 2.5);
        height: calc(var(--base) * 2.5);
        line-height: calc(var(--base) * 2.5);
        border-radius: 50%;
        background: var(--pr-accordion-counter-bg);
      }
    }

    .accordion-body {
      position: relative;
      padding: 0 calc(var(--base) * 2) 0 calc(var(--base) * 5);
    }
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--pr-color);
    background-color: var(--pr-accordion-header-bg);
    border: 1px solid var(--pr-accordion-border);
    cursor: pointer;
    padding: var(--spacing-unit);
    outline: none;
    transition: background-color 0.6s ease;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAYAAAC58NwRAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AgHFAwNJY24UQAAAJVJREFUKM+lk8ENgzAMRZ9hgS4SiRFgDN+6QifpCD1mDFbIIQuwASNwcaSqKsEW/5RI/1n+jiMAqvoE3sCScy50JGb+2H2/ggar3PQAVlWdesBilV2QWIYJWM1Mrz1pBy8k37QHkt8eryD5F6wHydn4zqCBoO63FAodGmvo4UKrETG3bXWbG/DymgHGWmtJKW3A7PmiB2rUYYave/NSAAAAAElFTkSuQmCC);
    background-size: 0.6rem;
    background-repeat: no-repeat;
    background-position: right 2rem center;

    &:hover,
    .active {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAI9JREFUeNpiZACCyMjIBCDVD8SOy5cvv8BAAgDqNQBS+4G4EKh3ASPUsPlQ+Q+kGIpkmABUKJEJ6jIYAEnshyok1TAQ6AcZ6Ah1GdGG4jAM7DtGQgrQvU9ILSOxColVw0is7VA2QV8wkhA+DMQECSMJgc5AyDCcBhIwFG9aZSQxrRFM+IwkJGAGYnIRQIABACQuXCKovu2mAAAAAElFTkSuQmCC);
      background-size: 1rem;
      border: 1px solid var(--pr-accordion-border);
    }

    .title {
      display: flex;
      align-items: center;
      max-width: 80%;
      margin: 0 auto 0 var(--base);
    }
  }

  .accordion-body {
    overflow: auto;
    transition: max-height 0.6s ease-in-out;
    padding: 0 calc(var(--base) * 2) 0;
    border-left: 1px solid var(--pr-accordion-border);
    border-right: 1px solid var(--pr-accordion-border);

    pre {
      white-space: normal;
    }

    label {
      display: flex;
      align-items: center;
      height: 30px;
    }
  }
}
