*::-webkit-scrollbar {
width: 1.4rem;
height: 1.4rem;
background-color: transparent;
}
*::-webkit-scrollbar-track:vertical {
    border-left: 1px solid var(--pr-scrollbars-track-border);
}
*::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid var(--pr-scrollbars-track-border);
}

*::-webkit-scrollbar-thumb {
    min-width: 3rem;
    min-height: 3rem;
    background-color: var(--pr-scrollbars-thumb);
    border: 0.4rem solid transparent;
    -moz-background-clip: content;     /* Firefox 3.6 */
    -webkit-background-clip: content;  /* Safari 4? Chrome 6? */
    background-clip: content-box;
    border-radius: 1rem;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: var(--pr-scrollbars-thumb-hover);
    border-color: transparent;
}
*::-webkit-scrollbar-corner {
    background-color: transparent;
    border-left: 1px solid var(--pr-scrollbars-corner);
    border-top: 1px solid var(--pr-scrollbars-corner);
}
.with-custom-css-scrollbars * {
    scrollbar-width: thin;
    scrollbar-color: var(--pr-scrollbars-thumb) var( --pr-bg-body);
}
