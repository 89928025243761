.navbar {
  position: relative;
  display: flex;
  width: 100%;
  height: var(--header-height);
  background-color: var(--pr-bg-navbar);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.navbar-container {
  @extend %contentCenter;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav {
  list-style: none;
  height: var(--header-height);
}
