.msg {
  position: fixed;
  bottom: var(--spacing-unit);
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  border-radius: var(--border-radius);

  &.msg--error {
    color: #a00;
    border: 2px solid #a00;
  }
}
