:root {
  --base: 1rem;
  --spacing-unit: 2rem;
  --border-radius: .4rem;

  // Grid
  --grid-template-columns: 1fr;
  --grid-template-columns-2-3: repeat(auto-fit, minmax(400px, 1fr));
  --grid-template-columns-3-2: repeat(auto-fit, minmax(1fr));

  --grid-gap: 2rem;

  // Common colors
  --white-bg-color: #ffffff;
  --c-primary: #1890ff;
  --c-primary-accent: #45a3fb;
  --c-success: #0be881;
  --c-secondary: #ffcf00;
  --c-danger: #ff4d4f;

  // Header
  --header-height: 6rem;

  // Header navigation
  --nav-direction: row;
  --nav-icon-size: 2rem;


  // Light theme

  --pr-bg-body: #ffffff;
  --pr-bg-header: #ffffff;
  --pr-header-logo: #373536;
  --pr-bg-navbar: #ffffff;
  --pr-navbar-color: rgba(0, 0, 0, 0.7);
  --pr-color: rgba(0, 0, 0, 0.85);
  --pr-border-color: rgba(0, 0, 0, 0.2);
  --pr-border-color-hover: rgba(0, 0, 0, 0.3);
  --pr-box-shadow: rgba(0, 0, 0, 0.05);
  --pr-box-shadow-focus: rgba(24, 144, 255, 0.6);

  --pr-nav-color: rgba(0,0,0,.85);

  --pr-input-bg: #ffffff;
  --pr-input-disabled-bg: #ecf0f1;
  --pr-input-shadow: rgba(24, 144, 255, 0.6);
  --pr-input-placeholder: rgba(0, 0, 0, 0.35);

  --pr-card-bg: #ffffff;

  --pr-btn-color: rgba(0, 0, 0, 0.7);
  --pr-btn-border-color: rgba(0, 0, 0, 0.2);
  --pr-btn-border-color-focus: rgba(0, 0, 0, 0.2);
  --pr-btn-bg: #ffffff;
  --pr-btn-bg-hover: #f7f7f7;
  --pr-btn-tag-bg: #cccccc;

  --pr-accordion-header-bg: #f7f7f7;
  --pr-accordion-counter-bg: #e4e4e4;
  --pr-accordion-border: rgba(0,0,0,.2);

  --pr-table-border-color: #dadfe1;
  --pr-table-odd-bg: #ecf0f1;

  --pr-bg-alert: #ffffff;
  --pr-border-alert: rgba(0, 0, 0, 0.2);
  --pr-border-alert-outline: rgba(0, 0, 0, 0.5);

  --pr-scrollbars-track-border: rgba(0, 0, 0, 0.1);
  --pr-scrollbars-thumb: rgba(0, 0, 0, 0.25);
  --pr-scrollbars-thumb-hover: rgba(0, 0, 0, 0.4);
  --pr-scrollbars-corner: rgba(0, 0, 0, 0.1);

  --pr-loading-bg: rgba(255, 255, 255, 0.9);
  --pr-modal-bg: rgba(0, 0, 0, 0.8);
}

.dark-mode {
  --pr-bg-body: #25282c;
  --pr-bg-header: #191c20;
  --pr-header-logo: rgba(255, 255, 255, 0.8);
  --pr-bg-navbar: #111417;
  --pr-navbar-color: rgba(255, 255, 255, 0.65);
  --pr-color: rgba(255, 255, 255, 0.8);
  --pr-border-color: rgba(0, 0, 0, 0.3);
  --pr-border-color-hover: rgba(0, 0, 0, 0.4);
  --pr-box-shadow: rgba(0, 0, 0, 0.1);
  --pr-box-shadow-focus: rgba(24, 144, 255, 0.3);

  --pr-nav-color: rgba(255,255,255,.65);

  --pr-input-bg: rgba(255, 255, 255, 0.05);
  --pr-input-disabled-bg: rgba(0, 0, 0, 0.15);
  --pr-input-shadow: rgba(24, 144, 255, 0.3);
  --pr-input-placeholder: rgba(255, 255, 255, 0.2);

  --pr-card-bg: #191c20;

  --pr-btn-color: rgba(255, 255, 255, 0.8);
  --pr-btn-border-color: rgba(255, 255, 255, 0.025) transparent transparent transparent;
  --pr-btn-border-color-focus: rgba(0, 0, 0, 0.4);
  --pr-btn-bg: rgba(255, 255, 255, 0.05);
  --pr-btn-bg-hover: rgba(255, 255, 255, 0.1);
  --pr-btn-tag-bg: rgba(255, 255, 255, 0.1);

  --pr-accordion-header-bg: rgba(255, 255, 255, 0.02);
  --pr-accordion-counter-bg: #47494d;
  --pr-accordion-border: #47494d;

  --pr-table-border-color: #47494d;
  --pr-table-odd-bg: rgba(0, 0, 0, 0.15);

  --pr-bg-alert: #191c20;
  --pr-border-alert: rgba(255, 255, 255, 0.1);
  --pr-border-alert-outline: rgba(255, 255, 255, 0.5);

  --pr-scrollbars-track-border: rgba(255, 255, 255, 0.05);
  --pr-scrollbars-thumb: rgba(255, 255, 255, 0.25);
  --pr-scrollbars-thumb-hover: rgba(255, 255, 255, 0.4);
  --pr-scrollbars-corner: rgba(255, 255, 255, 0.05);

  --pr-loading-bg: rgba(0, 0, 0, 0.9);
  --pr-modal-bg: rgba(0, 0, 0, 0.95);
}

@media screen and (min-width: $min-width) {
  :root {
    --max-width: 1280px;

    // Grid
    --grid-template-columns-2-3: minmax(400px, 2fr) 3fr;
  }
}
