.tooltip {
  display: inline-block;
  position: relative;
  line-height: 1;

  @include mobile {
    display: none;
  }

  svg {
    height: 15px;
    width: auto;
    opacity: .6;
    fill: var(--pr-nav-color);
  }

  &.error {

    svg {
      fill: var(--c-danger);
    }
  }

  > div {
    min-width: 300px;
    padding: 20px;
    color: #ffffff;
    background-color: #191c20;
    border-radius: var(--border-radius);
    font-size: 1.4rem;
    line-height: 1.5;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;

    i {
      position: absolute;
      width: 24px;
      height: 12px;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color:#191c20;
      }
    }

    &.bottom {
      top: 40px;
      left: 50%;
      transform: translate(-50%, 0);

      i {
        bottom: 100%;
        left: 50%;
        margin-left: -12px;

        &:after {
          left: 50%;
          transform: translate(-50%,50%) rotate(45deg);
        }
      }
    }

    &.bottom-left {
      top: 40px;
      right: calc(100% - 40px);

      i {
        bottom: 100%;
        right: 15px;

        &:after {
          right: 0;
          transform: translate(-50%,50%) rotate(45deg);
        }
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
}
