body {
  color: var(--pr-color);
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--pr-bg-body);
  background-image: none;

  &.dark-mode {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.content {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;

  &.has-buttons {

    @include tablet-landscape {
      height: calc(100% - 134px);
    }
  }
}

.main {
  @extend %contentCenter;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-unit) 0;
  height: 100%;

  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }

  > :first-child {
    margin-top: 0;
  }
}

section {
  text-align: center;

  &.with-image {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .image {
      max-width: 70%;
      height: auto;
      max-height: 400px;
      margin: 50px auto 20px
    }
  }
  @media (min-width: $min-width) {
    &.with-image {
      flex-direction: initial;
    }
    .image {
      margin: 20px;
      max-width: 50%;
      height: auto;
    }
  }
}

.hero {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: var(--spacing-unit) 0;
  border-bottom: 1px solid var(--pr-border-color);

  > div {
    @extend %contentCenter;
  }

  h3,
  ul {
    margin: 0;
  }

  h3 {
    display: flex;
    align-items: center;
  }
}

p {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  list-style: disc inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  padding: 0;
  margin: 0 0 var(--spacing-unit) 0;

  &.unstyled {
    list-style: none;
  }

  &.horizontal-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;

    li {
      margin-right: calc(var(--spacing-unit) / 2);
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1rem 0 1.5rem 3rem;
}

li {
  margin-bottom: 1rem;
}

strong {
  font-weight: bold;
}

.separator {
  margin-top: 0;

  @include tablet-portrait {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--pr-border-color);
  }
}
