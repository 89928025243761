header {

  .logo-salsita {
    display: flex;
    align-items: center;
    height: 100%;

    span {
      font-size: 2.2rem;
      font-weight: 500;
      margin-left: var(--spacing-unit);
      color: var(--pr-color);

      @include mobile {
        font-size: 1.8rem;
      }
    }

    svg {
      display: block;
      width: 80px;
      max-width: 120px;
      height: 48px;
      margin: 0 auto;

      .text {
        fill: var(--pr-header-logo);
      }

      .pepper path {
        &:first-child {
          fill: #bc221d;
        }
        &:nth-child(2) {
          fill: #31902d;
        }
      }

      @media (min-width: $min-width) {
        width: 100%;
        height: auto;
      }
    }
  }

  svg {
    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
    margin: calc(var(--nav-icon-size) / 4);
    fill: var(--pr-nav-color);
  }

  .c-username {
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;
    margin: 0 calc(var(--spacing-unit) / 2);
  }
}
