.dropdown {
  position: relative;

  .dropdown-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2rem 3rem 2rem 1rem;
    transition: all .3s ease-in-out;
    color: var(--pr-nav-color);
    border: none;
    box-shadow: none;

    &:hover {
      background-color: var(--pr-btn-bg-hover);
      color: var(--pr-nav-color);
    }

    &:after {
      content: '';
      width: 1rem;
      height: 1rem;
      right: 1rem;
      top: 1.7rem;
      position: absolute;
      transform: rotate(90deg);
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUCAYAAAC58NwRAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AgHFAwNJY24UQAAAJVJREFUKM+lk8ENgzAMRZ9hgS4SiRFgDN+6QifpCD1mDFbIIQuwASNwcaSqKsEW/5RI/1n+jiMAqvoE3sCScy50JGb+2H2/ggar3PQAVlWdesBilV2QWIYJWM1Mrz1pBy8k37QHkt8eryD5F6wHydn4zqCBoO63FAodGmvo4UKrETG3bXWbG/DymgHGWmtJKW3A7PmiB2rUYYave/NSAAAAAElFTkSuQmCC);
      background-size: 0.6rem;
      background-repeat: no-repeat;
    }
  }
}

.dropdown-menu {
  position: absolute;
  opacity: 0;
  z-index: 10;
  text-align: left;
  width: auto;
  min-width: 16.8rem;
  padding: 0.4rem;
  color: var(--pr-color);
  background-color: var(--pr-card-bg);
  background-image: none;
  border: 1px solid var(--pr-btn-border-color);
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.4rem var(--pr-box-shadow);
  /* Default position */
  top: -999rem;
  transform: translateY(-4rem);

  &:not(.dropdown-menu-right) {
    left: 0;
  }

  &.dropdown-menu-right {
    right: 0;
  }
}

/* Dropdown content */

.dropdown-content {
  display: block;
  padding: 1.2rem;

  > ul,
  > ol {
    margin-left: 0;
  }
}

/* Dropdown divider */

.dropdown-divider {
  height: 1px;
  background-color: var(--pr-border-color);
  margin: calc(var(--spacing-unit) / 4) 0;
}

/* Dropdown item */

.dropdown-item {
  display: block;
  white-space: nowrap;
  padding: 0.6rem 1.2rem;
  border-radius: calc(var(--border-radius) / 2);
  color: var(--pr-color);
  background-color: transparent;
  cursor: pointer;
  border: none;
  line-height: 1.4;
  font-size: 100%;
  text-align: left;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    text-decoration: none;
    color: var(--pr-color);
    background-color: var(--pr-btn-bg-hover);
  }
}

/* Dropdown header */

.dropdown-header {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.6rem 1.2rem;
  margin: 0;
  color: var(--pr-color);
}

/* Dropdown */

.dropdown.show .dropdown-menu,
.dropdown.toggle-on-hover:hover .dropdown-menu {
  top: 100%;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
}
