.card {
  position: relative;
  padding: var(--spacing-unit);
  color: var(--pr-color);
  background-color: var(--pr-card-bg);
  background-image: none;
  border-radius: var(--border-radius);
  border: 1px solid var(--pr-border-color);
  box-shadow: none;
}
