/* Inputs */

.form-control {
  display: block;
  width: 100%;
  height: 3.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: var(--pr-color);
  background-color: var(--pr-input-bg);
  border: 1px solid var(--pr-border-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.form-control-sm {
    height: 2.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 1.2rem;
  }

  &.form-control-lg {
    height: 4rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 1.8rem;
  }

  &:hover {
    color: var(--pr-color);
    background-color: var(--pr-input-bg);
    border-color: var(--pr-border-color-hover);
    box-shadow: none;
  }

  &:focus {
    color: var(--pr-color);
    background-color: var(--pr-input-bg);
    border-color: var(--pr-border-color-hover);
    box-shadow: 0 0 0 0.2rem var(--pr-input-shadow);
    outline: none;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    color: var(--pr-color);
    background-color: var(--pr-input-disabled-bg);

    &:hover {
      cursor: not-allowed;
      opacity: 0.6;
      color: var(--pr-color);
      background-color: var(--pr-input-disabled-bg);
    }
  }
}

textarea.form-control {
  max-width: 100%;
  min-height: 10rem;
  padding: 0.8rem;
  line-height: 1.5;
  font-family: inherit;
  resize: vertical;

  &.form-control-sm {
    min-height: 8rem;
    padding: 0.8rem;
  }

  &.form-control-lg {
    min-height: 14rem;
    padding: 0.8rem;
  }
}

::-webkit-input-placeholder {
  color: var(--pr-input-placeholder);
}
:-ms-input-placeholder {
  color: var(--pr-input-placeholder);
}
::placeholder {
  color: var(--pr-input-placeholder);
}

.dark-mode {

  .select__control,
  .select__menu-list {
    background-color: #303236;
    border: 1px solid rgba(0,0,0,.2);
  }

  .select__indicator,
  .select__indicator-separator {
    opacity: .3;
  }

  .select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .select__single-value {
    color: inherit;
  }

  .select__multi-value {
    background-color: rgba(0,0,0,.2);
    border-color: rgba(0,0,0,.2);
  }

  .select__multi-value__label {
    color: inherit;
  }
}

.select__multi-value {
  padding: 0.2em 0;
  border: 1px solid #ccc;
  font-size: 115%;
}

.select__multi-value__label {
  padding: 0 3px;
}

.form-group {
  margin-bottom: var(--spacing-unit);

  &--checkbox {
    margin-bottom: 1rem;

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    input[type="checkbox"] {
      margin-right: 1rem;
    }
  }

  &--btn {
    display: flex;
    align-items: center;

    .btn {
      margin-left: 1rem;
    }
  }
}

input[type="radio"] {
  margin: 0 calc(var(--spacing-unit) / 2) 0 0;
}
