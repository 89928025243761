$spaceamounts: (0, 5, 10, 20, 30, 40);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }

  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

.h-flex {
  display: flex !important;
  align-items: center !important;
}

.h-flex-space {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.h-icon {

  svg {
    width: var(--spacing-unit);
    margin-right: calc(var(--spacing-unit) / 2);
  }
}

.h-right {
  text-align: right !important;
}

.h-strong {
  font-weight: bold;
}

.h-grayscale {
  filter: grayscale(1);
}

.heading-with-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-unit);

  ul {
    @extend .unstyled;
    display: flex;
    margin: 0;

    li {
      margin: 0 0 0 var(--spacing-unit);
    }
  }
}

%contentCenter {
  max-width: 1140px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/* Media queries */

$mobile-width: 577px;
$tablet-width: 1023px;

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

/* Display helpers */

.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}

@include tablet-landscape {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
}
