
// TODO: need to be fixed
.icon {
  height: 15px;
  width: auto;
}

.icon-container {
  display: flex;
}
