.table {
  width: 100%;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;

  tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--pr-border-color);
  }

  @include tablet-portrait {
    thead {
      display: none;
    }

    tr,
    td {
      display: block;

      + tr {
        border-top: 1px solid var(--pr-border-color);
      }
    }

    td {
      border-bottom: 1px solid var(--pr-table-border-color);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.table-actions {
        justify-content: flex-end;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    tbody tr td:before {
      content: attr(data-title);
      font-weight: bold;
    }
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-weight: 700;
    padding: 1.5rem;
    position: sticky;
    top: 0;
    background-color: var(--pr-bg-body);
    box-shadow: inset 0 -2px 0 var(--pr-table-border-color);

    .table-sort {
      display: flex;
      align-items: center;
      padding-right: calc(var(--spacing-unit) * 1.5);
      position: relative;

      &:hover {

        .btn-sort {
          display: block;
        }
      }

      .btn-sort {
        width: var(--spacing-unit);
        height: var(--spacing-unit);
        margin-left: calc(var(--spacing-unit) / 2);
        display: none;
        position: absolute;
        right: 0;
        top: 1px;

        &.is-active {
          display: block;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  td {
    padding: 1.2rem 1.5rem;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0;
      }

      &.table-list {

        li {

          &:not(:last-child):after {
            content: ', ';
            white-space: pre;
          }
        }
      }

      &.table-dates {
        flex-direction: column;
      }
    }
  }

  &.table-striped {

    tbody {

      tr:nth-child(odd) {
        background-color: #ecf0f1;
      }

      tr:nth-child(odd) {
        background-color: #ecf0f1;
      }
    }
  }
}

.table-actions {
  display: flex;
  justify-content: flex-end;

  li {
    width: 3rem;
  }
}

.table-filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  input {
    width: 300px;
  }
}
