.btn {
  display: inline-block;
  height: 3.4rem;
  line-height: 3.4rem;
  padding: 0 1.5rem;
  font-size: 1.4rem;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: var(--pr-btn-color);
  background-color: var(--pr-btn-bg);
  // background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--pr-btn-border-color);
  border-radius: var(--border-radius);
  box-shadow: 0 0.2rem 0 var(--pr-box-shadow);
  box-sizing: border-box;

  &:hover {
    text-decoration: none;
    color: var(--pr-btn-color);
    background-color: var(pr-btn-bg-hover);
    // background-image: none;
    border-color: var(--pr-btn-border-color);
    box-shadow: 0 0.2rem 0 var(--pr-box-shadow);
  }

  &:focus,
  &.active {
    color: var(--pr-btn-color);
    background-color: var(-pr-btn-bg);
    background-image: none;
    border-color: var(--pr-border-color);
    box-shadow: 0 0 0 0.2rem var(--pr-box-shadow-focus);
    outline: 1px dotted transparent;
    outline-offset: 0.2rem;

    &:hover {
      color: var(--pr-btn-color);
      background-color: var(pr-btn-bg-hover);
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    color: var(--pr-btn-color);
    background-color: var(-pr-btn-bg);
    background-image: none;
    border-color: var(--pr-btn-border-color-focus);
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: var(--pr-btn-color);
      background-color: var(-pr-btn-bg);
      background-image: none;
      border-color: var(--pr-btn-border-color);
      box-shadow: none;
      outline: none;
    }
  }

  + .btn {
    margin-left: var(--spacing-unit);
  }
}

/* Small button */

.btn-sm {
  height: 2.6rem;
  line-height: 2.6rem;
  padding: 0 1rem;
  font-size: 1.2rem;
}

/* Large button */

.btn-lg {
  height: 4rem;
  line-height: 4rem;
  padding: 0 2rem;
  font-size: 1.8rem;
}

/* Block button */

.btn-block {
  display: block;
  width: 100%;
}

/* Button with icon */

.btn-with-icon {

  img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
  }
}

/* Button with small icon left */

.btn-with-icon-left {

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    fill: currentColor;
  }
}

/* Button icon */

.btn-icon {
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  padding: 0;
  border-width: 0;
  box-shadow: none;
  cursor: pointer;
  opacity: .7;
  transition: opacity .2s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--pr-btn-color);
  }

  &:hover {
    opacity: 1;
  }

  + .btn-icon {
    margin-left: 2rem;
  }
}

/* Link button */

.btn.btn-link {
  color: #1890ff;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
  box-shadow: none;

  &:hover {
    color: #45a3fb;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
    box-shadow: none;
  }

  &:focus,
  &.active {
    color: #1890ff;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgba(24, 144, 255, 0.6);
    outline-color: transparent;

    &:hover {
      color: #45a3fb;
      background-color: transparent;
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    color: #1890ff;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: #1890ff;
      background-color: transparent;
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      outline: none;
    }
  }
}

/* Primary button */

.btn.btn-primary {
  color: #ffffff;
  background-color: #1890ff;
  background-image: none;
  border-color: #1890ff;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);

  &:hover {
    color: #ffffff;
    background-color: #45a3fb;
    background-image: none;
    border-color: #45a3fb;
    box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  }

  &:focus,
  &.active {
    color: #ffffff;
    background-color: #1890ff;
    background-image: none;
    border-color: #1890ff;
    box-shadow: 0 0 0 0.3rem rgba(24, 144, 255, 0.3);
    outline-color: transparent;

    &:hover {
      color: #ffffff;
      background-color: #45a3fb;
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    color: #ffffff;
    background-color: #1890ff;
    background-image: none;
    border-color: #1890ff;
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #1890ff;
      background-image: none;
      border-color: #1890ff;
      box-shadow: none;
      outline: none;
    }
  }
}

/* Success button */

.btn.btn-success {
  color: rgba(0, 0, 0, 0.85);
  background-color: #0be881;
  background-image: none;
  border-color: #0be881;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #81eea8;
    background-image: none;
    border-color: #81eea8;
    box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  }

  &:focus,
  &.active {
    color: rgba(0, 0, 0, 0.85);
    background-color: #0be881;
    background-image: none;
    border-color: #0be881;
    box-shadow: 0 0 0 0.3rem rgba(11, 232, 129, 0.3);
    outline-color: transparent;

    &:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: #81eea8;
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    color: rgba(0, 0, 0, 0.85);
    background-color: #0be881;
    background-image: none;
    border-color: #0be881;
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.85);
      background-color: #0be881;
      background-image: none;
      border-color: #0be881;
      box-shadow: none;
      outline: none;
    }
  }
}

/* Secondary button */

.btn.btn-warning {
  color: rgba(0, 0, 0, 0.85);
  background-color: #ffcf00;
  background-image: none;
  border-color: #ffcf00;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #ffe04d;
    background-image: none;
    border-color: #ffe04d;
    box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  }

  &:focus,
  &.active {
    color: rgba(0, 0, 0, 0.85);
    background-color: #ffcf00;
    background-image: none;
    border-color: #ffcf00;
    box-shadow: 0 0 0 0.3rem rgba(255, 207, 0, 0.3);
    outline-color: transparent;

    &:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffe04d;
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    color: rgba(0, 0, 0, 0.85);
    background-color: #ffcf00;
    background-image: none;
    border-color: #ffcf00;
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffcf00;
      background-image: none;
      border-color: #ffcf00;
      box-shadow: none;
      outline: none;
    }
  }
}

/* Danger button */

.btn.btn-danger {
  color: #ffffff;
  background-color: #ff4d4f;
  background-image: none;
  border-color: #ff4d4f;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);

  &:hover {
    color: #ffffff;
    background-color: #ed586c;
    background-image: none;
    border-color: #ed586c;
    box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.05);
  }

  &:focus,
  &.active {
    color: #ffffff;
    background-color: #ff4d4f;
    background-image: none;
    border-color: #ff4d4f;
    box-shadow: 0 0 0 0.3rem rgba(245, 34, 45, 0.3);
    outline-color: transparent;

    &:hover {
      color: #ffffff;
      background-color: #ed586c;
      background-image: none;
    }
  }

  &.disabled,
  &:disabled {
    color: #ffffff;
    background-color: #ff4d4f;
    background-image: none;
    border-color: #ff4d4f;
    box-shadow: none;
    outline: none;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #ff4d4f;
      background-image: none;
      border-color: #ff4d4f;
      box-shadow: none;
      outline: none;
    }
  }
}

/* Tags buttons */

.btn-tag {
  display: flex;
  align-items: center;
}

.btn-tag-mark {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: var(--pr-btn-tag-bg);
  margin-right: 1rem;

  &.checked {
    background-color: var(--c-success);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: .5rem;
      left: .4rem;
      width: .8rem;
      height: .4rem;
      border-bottom: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      transform: rotate(-45deg);
    }
  }
}

/* Square button */

.btn-square {
  width: 3.4rem;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
  }
}
.btn-square.btn-sm {
  width: 2.6rem;
  padding-left: 0;
  padding-right: 0;
}
.btn-square.btn-lg {
  width: 4rem;
  padding-left: 0;
  padding-right: 0;
}

/* Rounded button */

.btn-rounded {
  border-radius: 3.2rem;
}
.btn-rounded.btn-sm {
  border-radius: 2.6rem;
}
.btn-rounded.btn-lg {
  border-radius: var(--border-radius);
}

/* Buttons group */

.btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: calc(var(--spacing-unit) / 2);
  box-sizing: border-box;
  background-color: var(--pr-bg-body);
  box-shadow: 0 -1px 0 var(--pr-table-border-color);

  @include tablet-landscape {
    position: fixed;
    z-index: 9;
  }

  &--modal {
    position: relative;
    box-shadow: none;
    padding: 0;
  }

  > div {
    @extend %contentCenter;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include tablet-portrait {
      width: 100%;
    }
  }

  .btn {
    margin-bottom: calc(var(--spacing-unit) / 2);
  }
}

.btn-group-simple {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .btn {
    margin-bottom: calc(var(--spacing-unit) / 2);
  }
}

.btn-group-editor {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;

    li {
      margin-left: calc(var(--spacing-unit) / 2);
    }
  }

  .btn {
    width: 2.4rem;
    height: 2.4rem;

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }

    &.btn-primary svg {
      fill: #ffffff;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--pr-color);

    svg {
      fill: var(--pr-color);
    }
  }

  .btn-primary .icon {
    color: #fff;
  }

  .text-heading {
    font-family: serif;
    font-weight: bold;
    background-color: transparent;
    font-size: 1.2rem;
  }

  .btn-primary {
    .text-heading {
      font-family: serif;
      background-color: #1890ff;
    }
  }
}

/* Button switcher/toggle */

.btn-toggle {

  .btn {
    border-radius: 0;
    border-bottom: none;
    height: 4rem;
    line-height: 4rem;
    padding: 0 4rem;

    &:first-child {
      border-radius: var(--border-radius) 0 0 0;
    }

    &:last-child {
      border-radius: 0 var(--border-radius) 0 0;
    }

    + .btn {
      margin-left: 0;
      border-left: none;
    }
  }

  + .card {
    border-top-left-radius: 0;
  }
}
