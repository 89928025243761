.modal {
  position: absolute;
  top: 0; /* Can be used to animate the modal from -100% to 0 */
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--pr-modal-bg);
  transition: all .4s cubic-bezier(.25, .8, .25, 1);
  z-index: 99;
  outline: none;
}

.modal-dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0; /* Can be used to animate the modal from -100% to 0 */
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-content {
  margin: auto;
  width: 40rem;
  max-width: 100%;
  padding: calc(var(--spacing-unit) * 1.5);
  color: var(--pr-color);
  background-color: var(--pr-card-bg);
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
  box-shadow: none;
  box-sizing: border-box;
  position: relative;

  @include mobile {
    width: 90%;
  }
}

.modal-title {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 0;
}

.modal-content-media {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;

  img,
  video {
    display: block;
  }
}

.modal-full {

  .modal-content {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: calc(var(--spacing-unit) * 1.5);
    color: var(--pr-color);
    background-color: var(--pr-card-bg);
    background-image: none;
  }
}

.modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: calc(var(--spacing-unit) / 2);
  right: calc(var(--spacing-unit) / 2);
  z-index: 10;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--pr-header-logo);
  }
}
