.login {
  display: flex;
  align-items: center;
  max-width: 1200px;
  flex: 1 auto;
  padding: var(--base);
  margin: 0 auto;
}

.local-login {
  max-width: 1200px;
  padding: var(--base);
  margin: 0 auto;
}

.login-google {
  margin: 5em auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
