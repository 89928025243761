.breadcrumb {
  margin-bottom: 0;
  display: flex;

  li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;

    &:before {
      content: "\002F";
      margin-left: 1rem;
      margin-right: 1.5rem;
    }

    &:first-child:before {
      display: none;
    }

    &.active a {
      pointer-events: none;
      color: inherit;
    }
  }
}
