footer {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  text-align: left;

  > div {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: var(--base);
  }
}
