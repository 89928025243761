.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  > * {
    z-index: 1;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--pr-loading-bg);
    position: absolute;
    left: 0;
    top: 0;
  }
}
