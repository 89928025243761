// will be removed

a {
  color: var(--c-primary);
  text-decoration: none;
}

input:focus {
  outline: none;
}

ul {
  list-style: none;
}

.select-box {
  margin-bottom: 2rem;
  max-width: 600px;
}

// sass variables for media quesry
$min-width: 840px;

// custom ressets
a, summary {
  outline: none;
}

// generic
@import 'generic/reset';
@import 'generic/helpers';
@import 'generic/typography';

// utils
@import 'utils/scrollbars';

// components
@import 'components/accordion';
@import 'components/alerts';
@import 'components/breadcrumb';
@import 'components/button';
@import 'components/card';
@import 'components/dropdown';
@import 'components/footer';
@import 'components/form';
@import 'components/grid';
@import 'components/header';
@import 'components/input';
@import 'components/layout';
@import 'components/message';
@import 'components/modal';
@import 'components/navbar';
@import 'components/svg';
@import 'components/table';
@import 'components/tooltip';

// pages
@import 'pages/error';
@import 'pages/login';
@import 'pages/overlay';

@import 'variables';
