label {
  display: block;
  margin-bottom: .6rem;
}

.form-medium {
  max-width: 600px;
}

// #todo need complete rework

.feedback-form-buttons {
  margin: var(--base) 0 calc(var(--base) * 3);

  button + button {
    margin-left: var(--base);
  }
}

.form-date {
  display: flex;
  align-items: center;
  margin: var(--base) var(--base) var(--base) 0;

  p {
    margin: 0 var(--base) 0 0;
  }

  .react-datepicker__input-container button {
    @extend .form-control;
    min-width: 120px;
    cursor: pointer;
  }

  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: 1em;
  }
  .react-datepicker__time-list-item {
    margin-bottom: 0;
    line-height: 30px;
  }
  .react-datepicker__navigation {
    top: 1.2em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 100px;
  }
  .react-datepicker-time__header {
    text-transform: capitalize;
  }
}

.form-select-users {
  display: flex;
  flex-direction: row;

  > * {
    flex: 1 1 150px;
    padding: var(--base);
  }

  ul {
    padding: 0;
  }

  li {
    + li {
      margin-top: calc(var(--base) / 2);
    }

    [class^="btn-"] {
      width: 100%;
    }
  }

  [class^="btn-"] {
    padding: calc(var(--base) / 2);
  }
}

.form-error {
  color: #B63030;
  margin-bottom: calc(var(--base) * 2);
}
